<template>
  <div class="content-list-content">
    <div class="content-list-table">
      <div class="mb-2 d-flex justify-content-between mb-5 row">
        <h4 class="col-md-8 col-12 font-weight-bold font-title">
          ユーザー新規登録
        </h4>
        <div class="col-md-4 col-12">
          <button
            v-on:click="gotoDashboard()"
            class="float-md-right button-back-dashboard"
          >
            戻る
          </button>
        </div>
      </div>
      <div class="mb-5 d-flex justify-content-center">
        <h3 class="font-weight-bold font-title-section">
          -以下いずれかをお選びください-
        </h3>
      </div>
      <div v-if="listSetting && listSetting.length > 0">
        <div
          class="list-option mb-2 d-flex justify-content-center"
          v-for="(value, index) in listSetting"
          :key="index"
        >
          <div class="once-option">
            <router-link
              v-if="value.route"
              :to="{ name: value.route }"
              class=""
            >
              <button class="float-right button-option-setting font-title">
                {{ value.text }}
              </button>
            </router-link>
            <button
              v-else
              class="float-right button-option-setting font-title"
              style="cursor: text"
            >
              {{ value.text }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "globalSetting",
  components: {},
  data() {
    return {
      listSetting: [
        { text: "一般設定", route: "General Setting" },
        { text: "決済情報", route: "paymentSetting" },
        { text: "販売情報", route: "Sales Setting" },
        { text: "ストア設定", route: "store setting" },
        { text: "メール設定", route: "email setting" },
        { text: "BIレポート", route: "" },
      ],
    };
  },
  created() {
    //Fix domain
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listSetting.forEach((obj) => {
        obj.route += " domain";
      });
    }
  },
  computed: {},
  watch: {},
  methods: {
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
  },
};
</script>
